import { ref, Ref } from "vue";
import { ModulesDto } from "../common/dto.module";
import { Module } from "../common/enum.module";
import { SUPPORTED_LANGUAGES } from "../common/language/service.language";
import { useCommon } from "../composition-functions/common";
import { State } from "../enums/State";
import { IQueryParams } from "../interfaces/IParams";
import { IQueryListResult } from "../interfaces/IResponses";
import { assertNever } from "../util/assertNever";
import { MandantDto, MandantSaveDto } from "./dto.mandant";
import { MandantAttributeType } from "./enum.mandantAttributeType";

const currentMandant: Ref<null | MandantDto> = ref(null);

async function getMandant(mandantId: number): Promise<null | MandantDto> {
  const { getRequest } = useCommon();

  const response = await getRequest({ path: `/admin/mandants/${mandantId}` });
  const result = response.json();
  return result;
}

interface RefreshCurrentMandantParams {
  mandantId: number;
}

async function refreshCurrentMandant({ mandantId }: RefreshCurrentMandantParams): Promise<void> {
  currentMandant.value = await getMandant(mandantId);
}

export interface DeleteMandantParams {
  mandantId: number;
}

async function deleteMandant({ mandantId }: DeleteMandantParams) {
  const { deleteRequest } = useCommon();
  await deleteRequest({ path: `/admin/mandants/${mandantId}` });
}

interface GetMandantsByIdsParams {
  mandantIds: number[];
}

export interface GetMandantsByIdsResponse {
  id: number;
  name: string;
}

async function getMandantsByIds({ mandantIds }: GetMandantsByIdsParams): Promise<IQueryListResult<GetMandantsByIdsResponse>> {
  const { getRequest } = useCommon();

  const urlSearchParams = new URLSearchParams({
    mandantIds: mandantIds.toString(),
  });

  const response = await getRequest({ path: "/admin/mandants/getByIds", urlSearchParams });
  const result = await response.json();
  return result;
}

type GetMandantsParams = IQueryParams;

async function getMandants({ currentPage, pageSize, searchTerm }: GetMandantsParams): Promise<IQueryListResult<MandantDto>> {
  const { getRequest } = useCommon();

  const urlSearchParams = new URLSearchParams({
    searchTerm,
    currentPage: currentPage.toString(),
    pageSize: pageSize.toString(),
  });

  const response = await getRequest({ path: "/admin/mandants", urlSearchParams });
  const result = await response.json();
  return result;
}

interface SaveMandantParams {
  mandant: MandantSaveDto;
}

async function saveMandant({ mandant }: SaveMandantParams): Promise<{ id: number }> {
  const { postRequest } = useCommon();

  const response = await postRequest({ path: "/admin/mandants", data: JSON.stringify(mandant) });
  const result = await response.json();
  return result;
}

async function getMandantFilterOptions(searchTerm: null | string) {
  const getMandantsResult = await getMandants({
    currentPage: 0,
    pageSize: 20,
    searchTerm: searchTerm ?? "",
  });

  return getMandantsResult.items.map((mandant) => {
    return {
      value: mandant.id,
      label: `${mandant.id} - ${mandant.name}`,
    };
  });
}

interface UpdateModulesParams {
  mandantId: number;
  modules: ModulesDto;
}

async function updateModules({ mandantId, modules }: UpdateModulesParams) {
  const { putRequest } = useCommon();

  const moduleInputDtos = [];

  for (const module of modules) {
    switch (module.moduleType) {
      case Module.CONNECT: {
        moduleInputDtos.push({
          moduleType: module.moduleType,
          settings: {
            type: module.settings.type,
            state: module.settings.state,
            testPhaseUntil: module.settings.testPhaseUntil,
          },
        });
        break;
      }
      case Module.CRM_SYNC: {
        moduleInputDtos.push({
          moduleType: module.moduleType,
          settings: {
            type: module.settings.type,
            state: module.settings.state,
            testPhaseUntil: module.settings.testPhaseUntil,
          },
        });
        break;
      }
      case Module.NEWSLETTER_SYNC: {
        moduleInputDtos.push({
          moduleType: module.moduleType,
          settings: {
            type: module.settings.type,
            state: module.settings.state,
            testPhaseUntil: module.settings.testPhaseUntil,
          },
        });
        break;
      }
      case Module.REQUEST:
      case Module.CHECKIN:
      case Module.VOUCHER: {
        moduleInputDtos.push({
          moduleType: module.moduleType,
          settings: {
            state: module.settings.state,
            testPhaseUntil: module.settings.testPhaseUntil,
            shouldHideBrandingLabel: module.settings.shouldHideBrandingLabel ?? false,
          },
        });
        break;
      }
      case Module.DISCOUNT_CODE:
      case Module.LTS_ASTAT:
      case Module.LTS_TICKETING:
      case Module.REST_API:
      case Module.YANOVIS:
      case Module.LTS_SUEDTIROL_GUEST_PASS: {
        moduleInputDtos.push({
          moduleType: module.moduleType,
          settings: {
            state: module.settings.state,
            testPhaseUntil: module.settings.testPhaseUntil,
          },
        });
        break;
      }

      default: {
        assertNever(module);
      }
    }
  }

  await putRequest({
    path: `/admin/mandants/${mandantId}/modules`,
    data: JSON.stringify({ modules: moduleInputDtos }),
  });
}

type ModuleActivationDetails =
  | {
      moduleType: Module;
      isActivatable: false;
      message: string;
    }
  | {
      moduleType: Module;
      isActivatable: true;
    };
async function getModuleActivationDetails(mandantId: number): Promise<ModuleActivationDetails[]> {
  const { getRequest } = useCommon();

  const response = await getRequest({ path: `/admin/mandants/${mandantId}/modules/activationDetails` });
  const result = await response.json();
  return result;
}

function doesCurrentMandantHaveActiveAttribute(attributeKey: MandantAttributeType): boolean {
  if (!currentMandant.value) return false;
  return (
    currentMandant.value.attributes.some((attribute) => attribute.key === attributeKey && attribute.value === "true") ?? false
  );
}

function doesCurrentMandantHaveActiveModule(moduleType: Module): boolean {
  if (!currentMandant.value) return false;
  const foundModule = currentMandant.value.modules.find((module) => module.moduleType === moduleType);
  return foundModule !== undefined && foundModule.settings.state === State.ACTIVE;
}

function getModuleFromCurrentMandant<T extends Module>(moduleType: T): null | ({ moduleType: T } & ModulesDto[number]) {
  if (!currentMandant.value) return null;
  return (currentMandant.value.modules.find((module) => module.moduleType === moduleType) ?? null) as
    | null
    | ({ moduleType: T } & ModulesDto[number]);
}

function getMandantLanguages(mandant: MandantDto): SUPPORTED_LANGUAGES["mandant"][] {
  return mandant.languages.filter((language) => SUPPORTED_LANGUAGES.mandant.includes(language));
}

function getRequiredLanguagesForMandantDetails(mandant: MandantDto): SUPPORTED_LANGUAGES["mandant"][] {
  const enabledMandantLanguages = getMandantLanguages(mandant);
  const portalLanguages = SUPPORTED_LANGUAGES.default;
  const mandantDetailLanguages = new Set([...portalLanguages, ...enabledMandantLanguages]);

  return Array.from(mandantDetailLanguages);
}

export const useMandant = () => {
  return {
    currentMandant,
    getMandant,
    getMandantsByIds,
    getMandantFilterOptions,
    getMandants,
    saveMandant,
    deleteMandant,
    refreshCurrentMandant,
    updateModules,
    getModuleActivationDetails,
    doesCurrentMandantHaveActiveAttribute,
    doesCurrentMandantHaveActiveModule,
    getRequiredLanguagesForMandantDetails,
    getMandantLanguages,
    getModuleFromCurrentMandant,
  };
};
